<script setup lang="ts">
import type { UIFullScreenVideoProps } from './UIFullScreenVideo.props'

defineProps<UIFullScreenVideoProps>()

const { isIos } = useDevice()

const togglePause = (event: MouseEvent) => {
  const video = event.currentTarget as HTMLVideoElement
  if (video.paused) {
    video.play()
  } else {
    video.pause()
  }
}
</script>
<template>
  <div class="grid">
    <div
      class="full-screen-video-pdp relative inline-flex w-screen items-center justify-center overflow-hidden lg:w-[50vw]"
      :class="title ? 'max-h-[90vh]' : 'max-h-[100vh]'"
    >
      <video
        :muted="true"
        :loop="true"
        :autoplay="true"
        controlsList="nofullscreen nodownload"
        webkit-playsinline
        playsinline
        :poster="posterVideo(src)"
        @click="togglePause"
      >
        <source
          v-if="!isIos"
          :src="optimizeVideo(src, 'best', undefined, 'webm')"
          type="video/webm"
        />
        <source :src="optimizeVideo(src, 'best')" type="video/mp4" />
      </video>
    </div>
    <span
      v-if="title"
      class="flex h-[10vh] items-center justify-end px-4 lg:px-0"
    >
      {{ title }}
    </span>
  </div>
</template>
<style lang="scss">
.full-screen-video-pdp {
  // square aspect ratio screens
  @media (min-height: 1000px) and (min-width: 1024px) {
    width: 100vw !important;
  }
}
</style>
